import { datadogRum } from '@datadog/browser-rum';
import { useInitializationDataContext } from '../context/data-context/index';
import { getCookie } from '../utils/index';

/**
 * Adds an action to datadog with data automatically derived from the necessary contexts
 */
export const useDatadog = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  /**
   * Adds an action to datadog with data automatically derived from the necessary contexts
   *
   * @param actionName The name of the action to add
   * @param actionData The data to add to the action
   * @param overrides An object with optional overrides for the "user" and "project" objects
   */
  const addAction = (
    actionName: string,
    actionData: { [key: string]: unknown },
    overrides?: {
      user?: { [key: string]: unknown };
      project?: { [key: string]: unknown };
    },
  ) =>
    datadogRum.addAction(actionName, {
      ...actionData,
      project: {
        productId: initializedData?.product_id,
        projectId: initializedData?.project_id,
        productType: initializedData?.project_type_code,
        ...overrides?.project,
      },
      user: {
        accountId: initializedData?.account_id,
        accountType: getCookie('accountType'),
        customerId: initializedData,
        ...overrides?.user,
      },
    });

  return {
    addAction,
  };
};
