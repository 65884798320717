import React, { ReactElement, lazy, Suspense, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { SizeDrawer } from '@hallmark/web.page-components.print-on-demand.text-editor.size-drawer';
import { useAnalyticsContext } from '../../../context/analytics-context';
import {
  setIsTextDrawerOpen,
  setAllDrawersClosed,
  setIsDeleteConfirmationDialogOpen,
  setIsDeleteConfirmationDialogClosed,
  setIsClearConfirmationDialogClosed,
  setIsResetConfirmationDialogClosed,
  setIsImageEditDrawerOpen,
  useAppContext,
  setIsToasterOpen,
} from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { useCropContext } from '../../../context/crop-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { FabricObject } from '../../../global-types';
import { useActiveCanvas, useFeatureFlags, useIsOneToMany } from '../../../hooks';
import { useDatadog } from '../../../hooks/useDatadog';
import { deleteImage } from '../../../services';
import {
  getGroupedTextObject,
  getObjectByName,
  CanvasDataTypes,
  getObjectsByType,
  clearPhotoTextZone,
  convertPointToPixel,
  convertPixelToPoint,
  togglePhotoTextZoneButtons,
} from '../../../utils';
import { FontDrawer } from '../../card-controls/font-drawer';
import { addPhotozoneBackground } from '../../card-editor/card-editor-utils';
import { addEditableAreas } from '../../card-editor/utils';
import { PhotoDrawer } from '../../photo-drawer';
import { ImageEditDrawer } from '../image-edit-drawer/image-edit-drawer';
import { OrderDrawer } from '../order-drawer';
import { RotateDrawer } from '../rotate-drawer';
import { ScaleDrawer } from '../scale-drawer';
import { WamInstructionsDrawer } from '../wam-instructions-drawer';
import styles from './drawer-container.module.scss';
import { useImageUploadHandlers } from './hooks/useImageUploadHandlers';
import { handleOrderChange } from './utils/handle-order-change';

const DeleteConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.DeleteConfirmationDialog,
  })),
);

const ResetConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.ResetConfirmationDialog,
  })),
);

const ClearConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.ClearConfirmationDialog,
  })),
);

const ImageUploadDrawerComponent = lazy(() =>
  import('../../card-controls/image-upload-drawer').then((module) => ({
    default: module.ImageUploadDrawer,
  })),
);

export const DrawerContainer = (): ReactElement => {
  const canvas = useActiveCanvas();
  const [initialAngle, setInitialAngle] = React.useState(canvas?.current?.getActiveObject()?.angle ?? 0);
  const { appState, appDispatch } = useAppContext();
  const isOneToMany = useIsOneToMany();

  const { startCropping, isCropping, finishCropping, cancelCropping } = useCropContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const datadog = useDatadog();
  const {
    isImageUploadDrawerOpen,
    isTextDrawerOpen,
    isWamDrawerOpen,
    isRotationDrawerOpen,
    isSizeDrawerOpen,
    isImageEditDrawerOpen,
    isOrderDrawerOpen,
    isScaleDrawerOpen,
    isImageUploadToPhotoZoneOpen,
  } = appState;

  const { t } = useTranslation();
  const { SAS_DYNAMIC_BUTTONS, IS_PHOTO_DRAWER_ENABLED } = useFeatureFlags();

  const { trackDeleteElement, trackEditElement, updateEditFormats, isReplacingImage, trackWamTamReset } =
    useAnalyticsContext();
  const resetErrorMessage = t('drawerContainer.resetErrorMessage');
  const imageErrorTitle = t('drawerContainer.imageErrorTitle');

  const closeAllDrawers = useCallback(() => setAllDrawersClosed(appDispatch), [appDispatch]);
  const openImageEditDrawer = useCallback(() => setIsImageEditDrawerOpen(appDispatch), [appDispatch]);

  // TODO: Create a custom hook like this one for each drawer.
  const { onImageSelect, onImageUpload } = useImageUploadHandlers();

  const deleteSelectedObject = () => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject) {
      trackDeleteElement();
      canvas?.current?.remove(activeObject);
    }
    return activeObject;
  };

  const handleClearConfirmation = () => {
    if (canvas?.current) {
      clearPhotoTextZone(canvas.current, SAS_DYNAMIC_BUTTONS);
      setAllDrawersClosed(appDispatch);
      closeClearConfirmationDialog();
    }
  };

  const handleDeleteConfirmation = async () => {
    const activeObject = canvas?.current?.getActiveObject() as FabricObject;
    if (activeObject?.type === 'image') {
      const response = await deleteImage(initializedData?.project_id as string, activeObject?.name as string);

      if (activeObject.data.type === CanvasDataTypes.PhotoZoneImage) {
        datadog.addAction('remove-photo-from-card-face', {
          photo: activeObject.data,
        });
      } else if (activeObject.data.type === CanvasDataTypes.WamImage) {
        datadog.addAction('remove-handwriting-from-card-face', {
          handwriting: activeObject.data,
        });
      } else {
        datadog.addAction('remove-photo-from-user-zone', {
          photo: activeObject.data,
        });
      }

      if (activeObject?.data.type === CanvasDataTypes.PhotoZoneImage && canvas?.current) {
        addPhotozoneBackground(activeObject, canvas?.current, cardState);
      }
      if (response?.meta?.code >= 400) {
        setIsToasterOpen(appDispatch, {
          title: imageErrorTitle,
          children: resetErrorMessage,
          variant: ToastVariants.Error,
        });
        return closeDeleteConfirmationDialog();
      }
    }
    const iconToDelete = canvas?.current?.getObjects().find((object) => object.data?.parentName === activeObject.name);

    if (iconToDelete) {
      canvas?.current?.remove(iconToDelete);
    }

    const image = deleteSelectedObject();

    const isPhotoImage = image?.data?.type === CanvasDataTypes.PhotoZoneImage;
    if (isPhotoImage && canvas?.current) {
      const currentZoneId = image?.data?.photoZoneId;
      if (!currentZoneId) {
        return;
      }
      //restore the photo zones
      const photoZones = getObjectsByType(canvas.current, CanvasDataTypes.PhotoZone);
      const currentPhotoZone = photoZones.find((zone) => zone.data?.id === currentZoneId);
      if (currentPhotoZone) {
        currentPhotoZone.set({ visible: true });
        currentPhotoZone.data.hasContent = false;
      }
      // restore the photo zone button
      const photoButton = getObjectsByType(canvas.current, CanvasDataTypes.PhotoZoneButton).find(
        (button) => button.data?.photoZoneId === currentZoneId,
      );
      photoButton?.set({ visible: true });
      canvas.current.renderAll();
    }
    // Restore editable area buttons if all objects are removed from editable area
    if (canvas?.current) {
      const objects = canvas.current.getObjects();
      if (isUserZoneEmpty(objects)) {
        // User zone is empty, so populate user zone buttons
        const cardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`];
        const { editableAreas } = cardFace;
        if (editableAreas) {
          addEditableAreas(cardFace, cardState, true, isOneToMany);
        }
      }
    }
    setAllDrawersClosed(appDispatch);
    closeDeleteConfirmationDialog();
  };

  const isUserZoneEmpty = (objects: FabricObject[]) => {
    for (const object of objects) {
      if (
        object.data?.type?.includes(CanvasDataTypes.UserText) ||
        object.data?.type?.includes(CanvasDataTypes.UserImage) ||
        object.data?.type?.includes('pod-handwriting-zone')
      ) {
        return false;
      }
    }
    return true;
  };

  const handleRotateOpenDrawer = () => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject?.type === 'image') {
      openImageEditDrawer();
    } else {
      setIsTextDrawerOpen(appDispatch);
    }
  };

  const handleResetConfirmation = () => {
    const activeImage = canvas?.current?.getActiveObject() as fabric.Object;
    if (!activeImage) {
      return;
    }
    deleteImage(initializedData?.project_id as string, activeImage?.name as string).then((response) => {
      if (response?.meta?.code >= 400) {
        setIsToasterOpen(appDispatch, {
          title: imageErrorTitle,
          children: resetErrorMessage,
          variant: ToastVariants.Error,
        });
        closeResetConfirmationDialog();
      } else {
        const deletedImage = deleteSelectedObject();
        if (deletedImage && deletedImage.data?.zoneName && canvas?.current) {
          const photoTextZone = getObjectByName(deletedImage.data.zoneName, canvas.current) as fabric.Group;
          if (photoTextZone) {
            photoTextZone.data.hasContent = false;
            photoTextZone.setOptions({ selectable: true });
            togglePhotoTextZoneButtons(true, canvas.current, photoTextZone, SAS_DYNAMIC_BUTTONS);
            canvas?.current?.renderAll();
          }
        }
        setAllDrawersClosed(appDispatch);
        closeResetConfirmationDialog();

        if (deletedImage?.data?.zoneName?.indexOf(CanvasDataTypes.PhotoTextZone) !== -1) {
          //Track Wam/Tam reset
          trackWamTamReset(false);
        }
      }
    });
  };

  const onChangeOrder = (order: number) => {
    if (!canvas?.current) return;
    handleOrderChange(order, canvas.current).then(() => {
      updateEditFormats({ order: order.toString() });
    });
  };

  const openDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationDialogOpen(appDispatch);
  };

  const closeDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationDialogClosed(appDispatch);
  };

  const closeClearConfirmationDialog = () => {
    trackDeleteElement();
    setIsClearConfirmationDialogClosed(appDispatch);
    resetSelection();
  };

  const onSubmitSize = () => {
    setIsTextDrawerOpen(appDispatch);
  };

  const onSubmitRotate = () => {
    setInitialAngle(canvas?.current?.getActiveObject()?.angle ?? 0);
    handleRotateOpenDrawer();
  };

  const onSubmitOrder = () => {
    const activeObject = canvas?.current?.getActiveObject();

    if (activeObject?.type === 'image') {
      setIsImageEditDrawerOpen(appDispatch);
    } else {
      setIsTextDrawerOpen(appDispatch);
    }
  };

  const onFinishCropping = async () => {
    if (finishCropping) {
      await finishCropping();
    }
  };

  const onCloseImageEditDrawer = () => {
    if (!isReplacingImage.current) {
      trackEditElement();
      resetSelection();
    }
  };

  /**
   *  When size drawer is closed by cancel, revert text to its original size
   *
   * @param textObject - object to revert back to its original size
   * @param fontSize - the text object's original size

  * @returns void
  */
  const onSizeDrawerClose = (textObject: fabric.Textbox, fontSize: number) => {
    setIsTextDrawerOpen(appDispatch);
    textObject?.setOptions({ fontSize });
    canvas?.current?.renderAll();
  };

  // @todo enable once WAM is added
  // const openResetConfirmationDialog = () => {
  //   setIsResetConfirmationDialogOpen(appDispatch);
  // };

  const closeResetConfirmationDialog = () => {
    setIsResetConfirmationDialogClosed(appDispatch);
  };

  const resetSelection = () => {
    setAllDrawersClosed(appDispatch);
    if (canvas?.current) {
      canvas.current.discardActiveObject();
      canvas.current.renderAll();
    }
  };

  useEffect(() => {
    if (isTextDrawerOpen && canvas?.current) {
      const activeTextBox = getGroupedTextObject(canvas?.current) as undefined | fabric.Textbox;
      if (activeTextBox?.isEditing) {
        activeTextBox.hiddenTextarea?.focus();
      }
    }
  }, [isTextDrawerOpen]);

  useEffect(() => {
    setInitialAngle(canvas?.current?.getActiveObject()?.angle ?? 0);
  }, [canvas?.current?.getActiveObject()?.angle]);

  const OpenDrawer = useMemo(() => {
    if (isTextDrawerOpen) {
      return <FontDrawer />;
    }

    if (isImageEditDrawerOpen || isCropping) {
      const isHandwriting = canvas?.current?.getActiveObject()?.data?.isPodHandwriting;
      return (
        <ImageEditDrawer
          isOpen={isImageEditDrawerOpen || isCropping}
          onClose={onCloseImageEditDrawer}
          onCrop={startCropping}
          onFinishCropping={onFinishCropping}
          onCancelCropping={cancelCropping}
          isCropping={isCropping}
          onDelete={openDeleteConfirmationDialog}
          onImageSelect={openImageEditDrawer}
          isHandwriting={isHandwriting}
        />
      );
    }

    if (isWamDrawerOpen) {
      return (
        <WamInstructionsDrawer
          onImageSelect={openImageEditDrawer}
          isOpen={isWamDrawerOpen}
          onCrop={startCropping}
          onClose={closeAllDrawers}
        />
      );
    }

    if (isSizeDrawerOpen) {
      const textObject = getGroupedTextObject(canvas?.current) as fabric.Textbox;
      const textFontSize: number = textObject?.fontSize ?? 30;
      return (
        <div className={styles['drawer-container']}>
          <SizeDrawer
            handleSubmit={onSubmitSize}
            isOpen={isSizeDrawerOpen}
            onClose={() => onSizeDrawerClose(textObject, textFontSize)}
            sizeValue={convertPixelToPoint(textFontSize).toString()}
            sizeOnChange={(valueInRange: string) => {
              if (textObject) {
                const scaledFontSize = convertPointToPixel(parseInt(valueInRange));
                textObject.setOptions({ fontSize: scaledFontSize });
                updateEditFormats({ fontSize: valueInRange });
                canvas?.current?.renderAll();
                textObject.fire('changed');
              }
            }}
          />
        </div>
      );
    }

    if (isScaleDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <ScaleDrawer
            isOpen={isScaleDrawerOpen}
            onApply={openImageEditDrawer}
            onChangeCallback={(value) => updateEditFormats({ size: value.toString() })}
            onCancel={openImageEditDrawer}
          />
        </div>
      );
    }

    if (isRotationDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <RotateDrawer
            isOpen={isRotationDrawerOpen}
            onDrawerApply={onSubmitRotate}
            onDrawerCancel={handleRotateOpenDrawer}
            initialAngle={initialAngle}
          />
        </div>
      );
    }

    if (isOrderDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <OrderDrawer
            isOpen={isOrderDrawerOpen}
            onClose={closeAllDrawers}
            onSubmit={onSubmitOrder}
            handleOrderChange={onChangeOrder}
          />
        </div>
      );
    }

    return <React.Fragment />;
  }, [appState, isCropping, finishCropping, startCropping]);

  return (
    <div data-testid="drawer-container">
      {OpenDrawer}
      <Suspense fallback={null}>
        {IS_PHOTO_DRAWER_ENABLED ? (
          <PhotoDrawer />
        ) : (
          <ImageUploadDrawerComponent
            onImageSelect={onImageSelect}
            isOpen={isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen}
            onUpload={onImageUpload}
            onClose={closeAllDrawers}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        <DeleteConfirmationComponent
          isOpen={appState.isDeleteConfirmationDialogOpen}
          onClose={closeDeleteConfirmationDialog}
          onConfirm={handleDeleteConfirmation}
        />
      </Suspense>
      <Suspense fallback={null}>
        <ResetConfirmationComponent
          isOpen={appState.isResetConfirmationDialogOpen}
          onClose={closeResetConfirmationDialog}
          onConfirm={handleResetConfirmation}
        />
      </Suspense>
      <Suspense fallback={null}>
        <ClearConfirmationComponent
          isOpen={appState.isClearConfirmationDialogOpen}
          onClose={closeClearConfirmationDialog}
          onConfirm={handleClearConfirmation}
        />
      </Suspense>
    </div>
  );
};
