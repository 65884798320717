import { fabric } from 'fabric';
import { CardContextState } from '../../../context/card-context';
import {
  getCardFaceClipPath,
  getGroupedItemByName,
  hideMiddleControls,
  togglePhotoTextZoneButtons,
} from '../../../utils';
import { CanvasDataTypes } from '../../../utils/canvas';
import { getPodWamPosition } from './get-pod-wam-position';
import { removeEditableAreaButtons } from './remove-editable-area-buttons';

/**
 * Adds WAM image to canvas
 * @param {string} imageUrl - cardState to access card dimensions
 * @param {boolean} isPodProductCode - Boolean to determine if this is a POD card type
 * @param {CardContextState} cardState - cardState to access card dimensions, clippath dimensions, active zone
 * @param {React.RefObject<fabric.Canvas>} canvas - Canvas object to add image to
 * @param {string} imageId - Unique identifier for the image
 * @param {string} cleanUpVersionId - Clean up version ID for the image
 * @param {function} onImageSelect - Function to call when image is selected
 * @param {function} onCrop - Function to call when image is cropped
 * @param {boolean} sasDynamicButtons - Boolean to determine if dynamic buttons should be shown
 * @returns Promise
 */
export const addWamImageToCanvas = async (
  imageUrl: string,
  isPodProductCode: boolean,
  cardState: CardContextState,
  canvas: React.RefObject<fabric.Canvas> | undefined,
  imageId: string,
  cleanUpVersionId: string,
  onImageSelect: () => void,
  onCrop: () => void,
  sasDynamicButtons = false,
) => {
  const cardFaceClipPath = getCardFaceClipPath(cardState.cardFacesList[cardState.activeCardIndex], 0);
  const activeZone = canvas?.current?.getActiveObject() as fabric.Group;
  return fabric.Image.fromURL(
    imageUrl,
    (img) => {
      let left, top, width, height;
      if (isPodProductCode && cardFaceClipPath) {
        // We are adding a handwriting image to a POD card, so there is no pre-existing activeZone like in S&S
        // This card has userZones, so position WAM at top left of editable area
        width = 350;
        height = 350;
        left = cardFaceClipPath.left + cardFaceClipPath.width / 2 - 175;
        top = cardFaceClipPath.top + cardFaceClipPath.height / 2 - 175;
      } else if (!activeZone) {
        // If no activeZone or clip path, get WAM position based on region
        // This is a POD card with no user zone
        const { wamTop, wamLeft, wamWidth, wamHeight } = getPodWamPosition(cardState);
        top = wamTop;
        left = wamLeft;
        width = wamWidth;
        height = wamHeight;
      } else {
        // For S&S, place WAM into its pre-defined zone
        ({ left, top, width, height } = activeZone);
      }
      // TODO: When implementing, we need to set the right properties to position and size image as expected
      img.scaleToWidth(width as number);
      if (img.getScaledHeight() > (height as number)) {
        img.scaleToHeight(height as number);
      }

      const clipPathSettings = {
        left,
        top,
        originX: 'center',
        originY: 'center',
        width,
        height,
        absolutePositioned: true,
        evented: false,
      };
      const oImg = img.set({
        // For S&S, fit WAM into zone. For POD, no need to fit into zone
        left: isPodProductCode ? left : (left as number) - img.getScaledWidth() / 2,
        top: isPodProductCode ? top : (top as number) - (height as number) / 2,
        originX: 'left',
        originY: 'top',
        name: imageId,
        clipPath: cardFaceClipPath,
        data: {
          type: CanvasDataTypes.WamImage,
          version_id: cleanUpVersionId,
          // clipPathSettings only apply to S&S WAM
          clipPathSettings: activeZone ? clipPathSettings : null,
          zoneName: activeZone?.name || 'pod-handwriting-zone',
          isPodHandwriting: isPodProductCode,
          // identify if is a WAM image for S&S cards
          isPhotoTextZoneImg: !isPodProductCode,
        },
      });

      oImg.onSelect = () => {
        onImageSelect();
        return false;
      };

      hideMiddleControls(oImg);
      if (canvas?.current) {
        removeEditableAreaButtons(canvas.current);
      }
      canvas?.current?.add(oImg);
      if (activeZone) {
        // For S&S cards
        const zoneTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, activeZone);
        zoneTextbox?.setOptions({ visible: false });
        togglePhotoTextZoneButtons(false, canvas?.current as fabric.Canvas, activeZone, sasDynamicButtons);
        activeZone.setOptions({ selectable: false });
        activeZone.data.hasContent = true;
      }

      canvas?.current?.setActiveObject(oImg);
      onImageSelect();
      if (activeZone) {
        // For S&S cards
        onCrop();
      }
    },
    { crossOrigin: 'anonymous' },
  );
};
